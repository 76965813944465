import type * as React from 'react';
import { useTabs } from './tabs.context';
import cx from 'classnames';
import { sanitizeId } from './tabs.utils';

type TabButton = {
  activeClassName?: string;
  inactiveClassName?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * The `TabButton` component is used to setup the a11y attributes for the tab button. it accepts `className` and `activeClassName`/`inactiveClassName` props that are applied when the tab is active/inactive.
 */
const TabButton: React.FC<React.PropsWithChildren<TabButton>> = ({
  activeClassName,
  inactiveClassName,
  id,
  className,
  ...rest
}) => {
  const { active, addRef, onTabKeyDown, onTabSelect } = useTabs();
  const safeId = sanitizeId(id);
  const isActive = active === safeId;

  return (
    <button
      id={safeId}
      aria-controls={`tab-panel-${safeId}`}
      role="tab"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={() => onTabSelect(safeId)}
      onKeyDown={(e) => onTabKeyDown(e, safeId)}
      ref={(ref) => addRef(ref)}
      className={cx('appearance-none', className, {
        [activeClassName as string]: isActive && !!activeClassName,
        [inactiveClassName as string]: !isActive && !!inactiveClassName,
      })}
      type="button"
      {...rest}
    />
  );
};

export { TabButton };
export default TabButton;
