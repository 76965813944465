import type { CaptionProps } from '@dx-ui/osc-caption';

export const ImageUrlParam = {
  width: 'rw',
  height: 'rh',
} as const;

export type AspectRatio =
  | '3:4' // 0.75
  | '1:1'
  | '3:2' // 1.50
  | '4:3' // 1.33
  | '4:2'
  | '9:16'
  | '16:9' // 1.78
  | '21:9' // 2.33
  | '18:5' // 3.60
  // TODO: should we replace this with a standard aspect ratio?
  | '384:113'; // 24.898

export type TResponsiveImage = {
  /**
   * URL of the Image, single URL for all screen types
   */
  imageUrl: string;
  /**
   * Aspect Ratio of the Image, single Aspect Ratio for all screen types
   */
  aspectRatio: AspectRatio;
  /**
   * Alternative text of the Image (a11y)
   */
  altText: string;
  width: number;
  className?: string;
  wrapperClassName?: string;
  bandwidth?: number;
  /**
   * Caption link and text
   */
  captionData?: CaptionProps;
  onImgLoaded?: () => void;
  /**
   * use if the image is part of the element that is Largest Contentful Paint
   */
  priority?: boolean;
  hFull?: boolean;
  maxHeight?: string;
  id?: string;
};

export const DocsTResponsiveImage: React.FC<React.PropsWithChildren<TResponsiveImage>> = () => null;
